@import "assets/base.scss";
@import "globals.scss";

.successful-title {
  @extend .font-weight-bolder;
  @extend .text-black;
  @extend .mb-2;
  @extend .text-center;
  font-size: 45px;
  margin-top: 3rem;
}

.card-container {
  @extend .p-4;
  @extend .ml-auto;
  @extend .mr-auto;

  // Tablet
  @media (min-width: $breakpoint-md) {
    width: 32.5rem;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    width: 32.5rem;
  }
}
.card-title {
  @extend .font-weight-bold;
  @extend .text-center;
  @extend .mb-4;
  font-size: 18px;
}
.card-description {
  @extend .text-black;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.access-btn {
  @extend .text-center;
  @extend .font-weight-bold;
  @extend .mt-2;
  @extend .mb-2;
  width: 100%;

  // Tablet
  @media (min-width: $breakpoint-md) {
    width: 70%;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    width: 70%;
  }
}
