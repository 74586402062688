@import "assets/base.scss";
@import "globals.scss";

.headerModal{
    margin-bottom:25px;
    @media (max-width: $breakpoint-md) {
        width: 250px;
        margin-left: -55px;
      }
}
.footerModal{
    margin-top:25px;
    @media (max-width: $breakpoint-md) {
        width: 250px;
        margin-left: -55px;
      }
}

