//  Core
span {
  user-select: none;
  font-family: "Raleway";
}

@mixin scrollbar-properties {
  // &::-webkit-scrollbar { //HIDE SCROLLBAR
  //   height: 10px;
  //   width: 10px;
  // }

  &::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #bbb;
  }
}

.horizontal-scroll {
  overflow-y: scroll;
  padding: 10px 0;
  // @include scrollbar-properties;
}

.calendar-grid {
  display: inline-block;
}

.date-column-width {
  width: 85px;
  margin: 10px 0;
}

.header-date {
  padding-bottom: 10px;
  flex-direction: column;
  width: 100%;
}

.blues {
  background-color: blue;
}

.time-slot-selected {
  background-color: blue;
  border: solid 1px rgba(0, 0, 210, 0.7);
  border-radius: 5px;
  width: 65px;
  cursor: pointer;

  span.time-text {
    margin: 5px 0;
    font-size: 16px;
    font-weight: bold;
    color: white;
    display: block;
    text-align: center;
  }
}
.time-block-unable {
  background-color: white;
  border: solid 1px white;
  border-radius: 5px;
  width: 65px;
}

.time-block-unable span {
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  color: gray;
  display: block;
  text-align: center;
}

span.day {
  display: block;
  font-size: 12px;
  text-align: center;
}
span.date {
  color: gray;
  display: block;
  font-size: 14px;
  text-align: center;
}

div.time-block {
  background-color: #edf3fc;
  border: solid 1px #edf3fc;
  border-radius: 5px;
  width: 65px;
  cursor: pointer;

  span.time-text {
    margin: 5px 0;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 210, 0.7);
    display: block;
    text-align: center;
  }

  &:hover {
    border: solid 1px #edf3fc;
  }
}

div.time-block-disabled {
  margin: 10px 0;
  background-color: #eeeeee11;
  border: solid 1px #eeeeee11;
  border-radius: 5px;
  width: 80%;
  cursor: not-allowed;

  span {
    margin: 5px 0;
    font-size: 16px;
    color: gray;
    display: block;
    text-align: center;
  }
}

div.show-more-button {
  margin-top: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3em;
  cursor: pointer;
  &:hover {
    border: solid 1px gray;
  }
  span {
    color: blue;
    margin-left: 8px;
  }
}

.pagination-left,
.pagination-right {
  width: 15px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 30px;
}
