@import "assets/base.scss";
@import "globals.scss";

.page-wrapper {
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-between;
  @extend .min-vh-100;
}

.card-container {
  // Base
  @extend .p-4;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;

  // Mobile
  width: 90%;

  // Tablet
  @media (min-width: $breakpoint-md) {
    width: 600px;
  }

  // Desktop
  @media (min-width: $breakpoint-lg) {
    width: 900px;
    margin-bottom: 3.5rem;
  }
}
