@import "assets/base.scss";
@import "globals.scss";

.right-form-wrapper {
  width: 100%;

  // Tablet
  @media (min-width: $breakpoint-md) {
    // width: 70%;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    // width: 70%;
  }
}

.form-label {
  color: rgba(0, 0, 0, 0.38);
  padding: 0;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  // letter-spacing: 0.00938em;
  margin-bottom: 0.5rem;
  font-size: 11px;
}

.form-item {
  width: 50%;

  // Tablet
  @media (min-width: $breakpoint-md) {
    width: 33%;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    width: 33%;
  }
}

.form-item-checkbox {
  width: 48%;

  // Tablet
  @media (min-width: $breakpoint-md) {
    width: 24%;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    width: 24%;
  }
}
