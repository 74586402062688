@import "assets/base.scss";
@import "globals.scss";

.card-container {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 5px;
  margin: 35px 10px 0 10px;
  max-width: 340px;
  background-color: white;
}
.card-color-line {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: black;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 15px;
}
.card-text-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 100%;
}
.card-price-label {
  @extend .text-center;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 0;
}
.card-description-label {
  font-size: 1.5rem;
  text-align: center;
}
ul {
  padding-left: 0px;
  list-style: none;
  & > li {
    margin-top: 10px;
    font-size: 13px;
  }
}
