@import "assets/base.scss";
@import "globals.scss";

.page-wrapper {
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-between;
  @extend .min-vh-100;
}

.card-container {
  // Base
  @extend .p-4;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;

  // Mobile
  width: 90%;

  // Tablet
  @media (min-width: $breakpoint-md) {
    width: 700px;
  }

  // Desktop
  @media (min-width: $breakpoint-lg) {
    width: 1000px;
    margin-bottom: 3.5rem;
  }
}

.form-container {
  // Base
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  // Mobile & Tablet
  width: 100%;
  
  // Desktop
  @media (min-width: $breakpoint-md) {
    width: 480px;
  }
}

.text-field {
  @extend .mb-2;
}

.message-alert{
    background-color:red;
    color:white;
    font-size:20px;
    display:flex;
    justify-content:center;
    align-items:center;
}
