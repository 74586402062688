@import "assets/base.scss";
@import "globals.scss";

.body-import-container{
    p:nth-child(1){
        background-color: transparent;
        font-size: 15px;
        font-weight: normal;
    }
    .container-action-buttons{
        margin-top:15px;
        display:flex;
        justify-content:flex-end;
        button:nth-child(1){
        margin-left: 8px;
        color:#5383ff;
        font-weight:bold;
        }
        button:nth-child(2){
        margin-left: 8px;
        font-weight:bold;
        }
    }
}
