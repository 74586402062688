// @import "assets/base.scss";

// Breakpoints
$breakpoint-xsm: 370px; // min-width
$breakpoint-sm: 767px; //
$breakpoint-md: 966px; // min-width
$breakpoint-lg: 1025px; // min-width
$breakpoint-lg-profile-card: 1378px; // min-width

// Custom colors
$custom-blue: #0657ff;
$custom-green: #42c715;

// Custom classes to reuse
.cursor-pointer {
  cursor: pointer;
}

.nosession-site-bg-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  overflow: hidden;

  // Tablet
  @media (min-width: $breakpoint-md) {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  // Desktop
  @media (min-width: $breakpoint-lg) {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
