// Core

@import "core";

// Fonts

@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap");

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.custom-primary-btn {
  border-radius: 3px !important;
}
.custom-secondary-btn {
  border-radius: 3px !important;
  border: 1px solid #e0e0e0 !important;
  box-shadow: none !important;
}
// Modify material classNames
.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(1) !important;
  transform-origin: top left;
}
.MuiInputBase-root.Mui-disabled {
  color: #5d5d5d !important;
}
.MuiFormLabel-root.Mui-disabled[data-shrink="true"] {
  color: rgba(0, 0, 0, 0.8) !important;
}
.MuiCheckbox-colorPrimary.Mui-disabled {
  color: rgba(85, 131, 255, 0.45) !important;
}
.MuiRadio-colorPrimary.Mui-checked.Mui-selected {
  color: #9dd1cf !important;
}

.MuiAvatar-img {
  object-fit: contain !important;
}

// Data Table
.rdt_Pagination {
  border-radius: 0 0 10px 10px !important;
}
