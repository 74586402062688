@import "assets/base.scss";
@import "globals.scss";

.avatar-img {
  @extend .img-fluid;
  @extend .rounded;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100% !important;
}

.position-label {
  @extend .m-0;
  @extend .text-center;
  font-weight: 500;
}
.email-label {
  @extend .text-center;
  margin-bottom: 2rem;
  word-break: break-all;

  // Tablet
  @media (min-width: $breakpoint-md) {
    margin-bottom: 4rem;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    margin-bottom: 4rem;
  }
}
.appointments-indicator-label {
  font-weight: 500;
}

.severity-separator {
  height: 50px;
  background-color: black;
  width: 1px;
  margin: 0 2rem 0 2rem;
}

.severity-appointments-indicator {
  @extend .divider;
  @extend .my-2;
  @extend .border-1;
  @extend .w-50;
  @extend .bg-first;
  @extend .rounded;
  @extend .mx-auto;
}
