@import "assets/base.scss";
@import "globals.scss";

.page-wrapper {
  // Base
  @extend .text-black;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;

  // XS Mobile
  @media (min-width: $breakpoint-xsm) {
    background-size: cover;
  }

  // Tablet
  @media (min-width: $breakpoint-md) {
    background-size: cover;
  }

  // Desktop
  @media (min-width: $breakpoint-lg) {
    background-image: none !important;
    background-color: white;
  }
}

.image-container {
  display: none;
  // Desktop
  @media (min-width: $breakpoint-lg) {
    display: flex;
    width: 40%;
  }
}
.cover-image {
  display: none;
  //Desktop
  @media (min-width: $breakpoint-lg) {
    display: block;
    max-width: 100%;
    object-fit: contain;
    margin-left: 10%;
  }
}

.navigation-buttons {
  top: 0;
  width: 100%;
  z-index: 100;
  @media (min-width: $breakpoint-lg) {
    position: absolute;
  }
}

.form-container {
  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .ml-auto;
  @extend .mr-auto;

  // Desktop
  @media (min-width: $breakpoint-lg) {
    width: 60%;
  }
}
.form-title {
  @extend .font-weight-bold;
  @extend .text-center;

  // Desktop
  @media (min-width: $breakpoint-lg) {
    margin: 1.5rem 0 0 0;
  }
}
.form-subtitle {
  @extend .mb-4;
  @extend .text-black;

  // Desktop
  @media (min-width: $breakpoint-lg) {
    color: #c8c8d4;
  }
}
.form-card {
  width: 90%;
  padding-left: 0.2rem;
  padding-right: 0.2rem;

  // Desktop
  @media (min-width: $breakpoint-lg) {
    width: 75%;
    padding-left: 15%;
    padding-right: 15%;
    -webkit-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15) !important;
  }
}
.form-card-title {
  @extend .text-center;
  @extend .font-weight-bold;
  @extend .text-black;
}
.form-card-loader-container {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .my-2;
}
.form-card-register-here-label {
  // Base
  @extend .text-center;
  @extend .mb-3;
  @extend .font-weight-bold;

  // Mobile
  font-size: 0.9rem;

  // Desktop
  @media (min-width: $breakpoint-lg) {
    font-size: 1rem;
  }
}
.form-card-register-btn {
  @extend .w-100;
  @extend .mb-4;
  @extend .font-weight-bold;
  @extend .text-white;
  background-color: $custom-green !important;
}

.footer-rights {
  @extend .text-dark;
  @extend .text-center;
  @extend .font-weight-bold;
  font-size: 0.7rem;
  // Desktop
  @media (min-width: $breakpoint-lg) {
    margin-top: 1rem;
    font-size: 0.8rem;
  }
}

.form-card-rights-label {
  @extend .footer-rights;
  // Desktop
  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}

.outer-rights-label {
  @extend .footer-rights;
  display: none;
  // Desktop
  @media (min-width: $breakpoint-lg) {
    display: block;
  }
}
