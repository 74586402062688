// Core

body {
  .app-search-wrapper {
    background-color: $white;
    border-radius: $border-radius-lg;
    padding: ($spacer * 2) 0;
    // display: flex;
    //align-items: center;
    margin-bottom: 32px;
    padding: 10px;
    border: 1px solid lightgray;
  }

  .app-search-wrapper-with-tabs {
    background-color: $white;
    border-radius: $border-radius-lg;
    margin-bottom:50px;
    padding: 10px 10px 0;
    border:1px solid lightgray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .app-search-wrapper button{
    margin-left: 20px;
  }

  .app-search-wrapper2 {
    background-color: $white;
    border-radius: $border-radius-lg;
    padding: ($spacer * 2) 0;
    display: flex;
    align-items: center;
    height: 100px;
    margin-top: 0px;
    margin-bottom: 50px;
    border: 1px solid lightgray;
    padding: 10px;
  }

  .app-search-wrapper-activate-assistants {
    background-color: $white;
    border-radius: $border-radius-lg;
    padding: ($spacer * 2) 0;
    display: flex;
    align-items: center;
    height: 100px;
    margin-top: 0px;
    margin-bottom: 50px;
    border: 1px solid lightgray;
    padding: 10px;
  }

  .app-search-wrapper2 button {
    margin-left: 20px;
  }

  .app-search-icon {
    color: inherit;
  }

  .app-search-input {
    background: $white;
  }

  .search-results {
    transition: $transition-base;
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  .no-search-results {
    display: flex;
    min-height: 350px;
    align-items: center;
    justify-content: center;
    transition: $transition-base;
    opacity: 1;
    visibility: visible;
    text-align: center;
    height: auto;
  }

  .search-results-hidden {
    opacity: 0;
    height: 0;
    min-height: 0;
    visibility: hidden;
    overflow: hidden;
  }

  .app-search-popper {
    z-index: 1333;
  }
}
