.reset-date-filter-btn {
  width: 20px;
  height: 20px;
  font-size: 0.5rem;
  color: red;
  font-weight: bolder;
  border: 2px solid red;
  border-radius: 50%;
  align-self: flex-end;
  text-align: center;
  margin: 0 0.2rem 0.2rem 0;
}
.reset-date-filter-btn:hover {
  cursor: pointer;
}
