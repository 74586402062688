@import "assets/base.scss";

.card-container {
  @extend .card-box;
  @extend .mb-3;
  @extend .p-3;
}
.card-title {
  @extend .text-black;
  @extend .font-size-lg;
  @extend .pb-2;
  @extend .font-weight-bold;
}
.card-paragraph {
  @extend .text-black-50;
  @extend .pb-3;
}
.card-images-container {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  @extend .w-100;
  max-height: 200px;
}
.image-container {
  @extend .w-100;
  height: 200px;
  margin: 0 1rem;
}
.card-image {
  @extend .w-100;
  height: 200px;
  max-width: 400px;
  object-fit: contain;
}
