@import "assets/base.scss";
@import "globals.scss";

.main-content {
  @extend .d-flex;
  @extend .flex-column;
  align-items: center;
  height: 100%;
  margin: 0 15px;
}
.cards-container {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;

  // Tablet
  @media (min-width: $breakpoint-md) {
    width: 50%;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }

  // Desktop
  @media (min-width: $breakpoint-lg) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    width: 93%;
  }
}
.company-logo-container {
  @extend .w-100;
  @extend .mb-4;

  @media (min-width: $breakpoint-md) {
    width: 40vw;
  }
}
.company-logo-image {
  width: auto;
  height: 60px;
  max-height: 60px;
  display: block;
  margin: 0px auto;
  object-fit: cover;
}
