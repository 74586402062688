@import "assets/base.scss";
@import "globals.scss";

.resume-form-container {
  display: block;

  // Tablet
  @media (min-width: $breakpoint-md) {
    display: flex;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    display: flex;
  }
}
