@import "assets/base.scss";
@import "globals.scss";

.payments-container {
  border: solid 1px lightgray;
  width: 100%;
}

.payment-paypal {
  border-top: solid 1px lightgray;
  width: 100%;
}

.payment-transaction-info {
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: $breakpoint-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.payment-transaction-item {
  padding: 15px 0;
  p {
    margin: 0;
    font-weight: bold;
    color: lightgray;
  }
  b {
    font-size: 14px;
  }
}

.resume-container {
  @extend .w-100;
  @extend .mt-4;
  display: flex;
  flex-direction: column;
  @media (min-width: $breakpoint-md) {
    flex-direction: row;
  }
}