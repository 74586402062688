@import "assets/base.scss";
@import "globals.scss";

.title-heading-container {
  margin-top: 3.5rem;
  margin-bottom: -1.5rem;

  // Tablet
  @media (min-width: $breakpoint-md) {
    margin-top: 2.5rem;
    margin-bottom: -1rem;
  }

  // Desktop
  @media (min-width: $breakpoint-lg) {
    margin-top: 1.5rem;
    margin-bottom: -0.5rem;
  }
}
