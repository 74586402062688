@import "assets/base.scss";
@import "globals.scss";

.gridUser {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  .divPayment {
    width: 80%;
  }
  @media (max-width: $breakpoint-md) {
    grid-template-columns: 1fr;
    .divPayment {
      width: 95%;
    }
  }
}

.divParent {
  width: "100%";
  display: "flex";
  justify-content: "center";
  flex-direction: "column";
  align-items: "center";
}
