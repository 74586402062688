@import "assets/base.scss";
@import "globals.scss";

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;

  // Mobile
  width: 100%;

  // Tablet
  @media (min-width: $breakpoint-md) {
    width: 700px;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    width: 950px;
  }
  // Desktop - only for this card
  @media (min-width: $breakpoint-lg-profile-card) {
    width: 1100px;
  }
}
.new-profile-wrapper {
  padding: 2rem 1rem 1rem 1rem;
  width: 100%;
}

.resume-form-container {
  display: block;

  // Tablet
  @media (min-width: $breakpoint-md) {
    display: flex;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    display: flex;
  }
}

.resume-form-separator {
  display: none;
  
  // Tablet
  @media (min-width: $breakpoint-md) {
    display: block;
    min-height: 40rem;
    background-color: #dcdcdc;
    width: 1px;
    margin: 0 2rem;
  }
}
