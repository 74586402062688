@import "assets/base.scss";
@import "globals.scss";

.form-container {
  // Base
  margin: 0 auto;

  // Mobile & Tablet
  width: 100%;
  
  // Desktop
  @media (min-width: $breakpoint-md) {
    width: 330px;
  }
}

.text-field {
  @extend .mb-2;
}

.text-field-other {
  @extend .mb-2;
  margin-top: 10px;
}
