@import "assets/base.scss";
@import "globals.scss";

.main-content {
  @extend .d-flex;
  @extend .flex-column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  margin: 0 15px;
}
