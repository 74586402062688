.helper-error-text {
  color: #f83245;
  margin: 0;
  font-size: 12px;
  margin-top: 3px;
  text-align: left;
  font-family: Roboto, Helvetica, Arial, sansserif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin-bottom: 1rem;
}
