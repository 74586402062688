@import "assets/base.scss";

.dropzone-container {
  border: 1px dashed #e6e7f1;
  border-radius: 0.2rem;
  background-color: #f8f9ff;
  text-align: center;
  padding: 0.2rem 0 0.2rem 0;
  cursor: pointer;
}
.dropzone-label-container {
  @extend .text-dark;
  font-size: 0.8rem;
}
