@import "assets/base.scss";
@import "globals.scss";

.form-separator {
  display: none;

  // Tablet
  @media (min-width: $breakpoint-md) {
    display: block;
    height: 10rem;
    background-color: #dcdcdc;
    width: 1px;
    margin: auto 2rem auto 2rem;
  }
  // Desktop
  @media (min-width: $breakpoint-lg) {
    display: block;
    height: 20rem;
    background-color: #dcdcdc;
    width: 1px;
    margin: auto 2rem auto 2rem;
  }
}
