@import "assets/base.scss";
@import "globals.scss";

.import-user-container{
    padding:20px;
    width:500px;
    p:nth-child(1){
        font-size:25px;
        font-weight:bold;
    }
    p:nth-child(2){
        margin-top:-10px;
    }
    p:nth-child(3){
        margin-top:20px;
        a{
           color: #5383ff;
        }
    }
}